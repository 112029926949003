export const CUSTOMER_STATUS_UNDEFINED = 1;
export const CUSTOMER_STATUS_HOT = 2;
export const CUSTOMER_STATUS_COLD = 3;
export const CUSTOMER_STATUS_DISINTERESTED = 4;
export const CUSTOMER_STATUS_EA = 5;
export const CUSTOMER_STATUS_EF = 6;
export const CUSTOMER_STATUS_PA = 14;
export const CUSTOMER_STATUS_PF = 15;
export const CUSTOMER_STATUS_NA = 16;
export const CUSTOMER_STATUS_NF = 17;
export const CUSTOMER_STATUS_SUPPORT = 7;
export const CUSTOMER_STATUS_BASIC_SUB = 8;
export const CUSTOMER_STATUS_ESSENTIAL_SUB = 9;
export const CUSTOMER_STATUS_ELITE_SUB = 10;
export const CUSTOMER_STATUS_EP = 11;
export const CUSTOMER_STATUS_CANCELED_SUBSCRIPTION = 28;
export const CUSTOMER_STATUS_FINANCIAL_SOLUTION = 13;
export const CUSTOMER_STATUS_WAIT_CONTRACT_PAYMENT = 18;
export const CUSTOMER_STATUS_LEAD_PN_UNDEFINED = 12;
export const CUSTOMER_STATUS_LEAD_PN_DISINTERESTED = 20;
export const CUSTOMER_STATUS_LEAD_PN_HOT = 21;
export const CUSTOMER_STATUS_LEAD_PN_COLD = 22;
export const CUSTOMER_STATUS_LEAD_PN_SOLIDES = 23;
export const CUSTOMER_STATUS_LEAD_PN_TRANSFORMED = 24;
export const CUSTOMER_STATUS_LEAD_PN_DISQUALIFIED = 25;
export const CUSTOMER_STATUS_LEAD_PN_EA = 26;
export const CUSTOMER_STATUS_LEAD_PN_EF = 27;
export const CUSTOMER_STATUS_GESTAO = 29;
export const TOTAL_AMOUNT = "total_sum";
