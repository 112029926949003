<template>
    <b-form-group
        :label="label"
        :label-for="id"
    >
        <b-form-input
          :id="id"
          v-money="moneyFormat"
          :value="value"
          @input="handleInput"
          :class="{ 'is-invalid': thereIsError }"
        />
        <div class="invalid-feedback">
          <span v-if="thereIsError">
            {{ errorMessage }}
          </span>
        </div>
    </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput
} from 'bootstrap-vue'
import { VMoney } from 'v-money'
import { getBRLFormat } from '@/helpers/formatting'
export default {
  components: {
    BFormGroup,
    BFormInput
  },
  directives: {
    money: VMoney
  },
  props: {
    id: String,
    label: String,
    value: String,
    thereIsError: Boolean,
    errorMessage: String
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    moneyFormat: function () {
      return getBRLFormat()
    },
  },
  methods: {
    handleInput (content) {
      this.$emit('input', content)
    }
  }
}
</script>
