<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header position-relative"
  >
    <!-- Content Left -->
    <b-col
      v-if="breadcrumbTitle"
      class="content-header-left mb-2"
      cols="12"
      md="9"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in $route.meta.breadcrumb"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
              <template v-if="$route.meta.info">
                <b-button
                  :id="$route.meta.info ? `header-info-${$route.meta.info}`  : 'info-button'"
                  variant="flat"
                  class="btn-icon form-horizontal p-0"
                  style="margin-left: 2px"
                  @click="modalInfo($route.meta.pageTitle, $route.meta.info)"
                >
                  <feather-icon
                    icon="InfoIcon"
                    size="16"
                    class="text-muted cursor-pointer"
                  />
                </b-button>
              </template>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->

    <b-col
      class="content-header-right text-md-right d-md-block d-none mb-1 right-button"
      cols="12"
    >
      <slot />
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BButton,
  BPopover,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import getInfo from "@/helpers/info";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  props: {
    breadcrumbTitle: {
      type: Boolean,
      default: () => true,
    },
  },
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BPopover,
  },
  setup() {
    const { skin } = useAppConfig();

    return { skin };
  },
  methods: {
    getInfo,

    modalInfo(title, html) {
      this.$swal({
        backdrop: true,
        title: `Seja bem-vindo(a) à tela de ${title}!`,
        html: html,
        icon: "info",
        confirmButtonText: "Ok",
        allowOutsideClick: false,
        allowEscapeKey: false,
        width: "60vw",
        heightAuto: false,
        background: `${this.skin === "dark" ? "#283046" : ""}`,
        customClass: {
          popup: "modal-height",
          confirmButton: "btn btn-primary",
          title: "text-primary",
          htmlContainer: `announcement-body info-responsive ${
            this.skin === "dark" ? "text-white" : ""
          }`,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.popover {
  white-space: pre-wrap;
  max-width: 600px;
}

.right-button {
  position: absolute;
  top: -3.5rem;
  right: 0;
}
</style>