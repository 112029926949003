<template>
    <b-form-group
      :label="label"
      :label-for="id"
    >
      <v-select
        :id="id"
        :value="value"
        :placeholder="placeholder"
        @input="handleInput"
        :reduce="obj => obj.id"
        :options="options"
        :loading="loading"
        :disabled="disabled"
        label="name"
        @keyup.native="find($event)"
        :class="getSelectErrorClass()"
      >
        <template #no-options="{ search, searching, loading }">
          Sem resultados
        </template>
      </v-select>
      <div class="invalid-feedback">
        <span v-if="thereIsError">
          {{ errorMessage }}
        </span>
      </div>
    </b-form-group>
</template>

<script>
import {
  BFormGroup, BFormInput
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { getVueSelectErrorClass } from '@/helpers/validators'
export default {
  components: {
    BFormGroup,
    BFormInput,
    vSelect
  },
  props: {
    id: String,
    label: String,
    placeholder: String,
    value: Number,
    options: Array,
    loading: Boolean,
    thereIsError: Boolean,
    errorMessage: String,
    disabled: Boolean
  },
  data () {
    return {
      timer: null,
      content: this.value
    }
  },
  methods: {
    handleInput (content) {
      if (!content) {
        this.$emit('find', content)
      }
      this.$emit('input', content)
    },
    find(event) {
      let keyword = event.target.value
      if (keyword.length > 2) {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          this.$emit('find', keyword)
        }, 500)
      }
    },
    getSelectErrorClass() {
      return getVueSelectErrorClass(this.thereIsError)
    }
  }
}
</script>
