export default {
    button: [
      {
        label: "Hoje",
        attributes: {
          class:'btn btn-outline-primary btn-sm m-1'
        }
      },
    ],
    onClick: (index, fp) => {
      fp.setDate(new Date());
      fp.close()
      this.search()
    }
}