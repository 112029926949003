<template>
  <div>
    <b-sidebar
      id="sidebar-new-lead"
      sidebar-class="sidebar-lg"
      :visible="newLeadSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @shown="onShow"
      @change="(val) => mutateNewLeadSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h4 v-if="newLeadSidebar.id && isPotentialPartner">Editar lead PN</h4>

          <h4 v-else-if="newLeadSidebar.id && !isPotentialPartner">Editar lead cliente</h4>

          <h4 v-else-if="!newLeadSidebar.id && isPotentialPartner">Novo lead PN</h4>

          <h4 v-else>Novo lead cliente</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col cols="12">
              <b-form-group label="Nome" label-for="lead-name">
                <b-form-input
                  id="lead-name"
                  v-model="name"
                  :class="{ 'is-invalid': v$.name.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="E-mail" label-for="lead-email">
                <b-form-input
                  id="lead-email"
                  v-model="email"
                  type="email"
                  :class="{ 'is-invalid': v$.email.$error || duplicateEmail || emailTakenByConsultant }"
                  :style="
                    v$.cellPhone.$error || duplicateEmail || emailTakenByConsultant
                      ? 'border-color: #ea5455 !important;'
                      : ''
                  "
                  @blur="checkEmail(email)"
                />
                <div class="invalid-feedback">
                  <span v-if="duplicateEmail"> E-mail duplicado </span>
                </div>
                <div class="invalid-feedback">
                  <span v-if="emailTakenByConsultant"> E-mail utilizado por um consultor </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Celular" label-for="lead-cellPhone">
                <vue-tel-input
                  id="lead-cellPhone"
                  v-model="cellPhone"
                  :class="{
                    'is-invalid': v$.cellPhone.$error || duplicatePhone,
                  }"
                  :style="
                    v$.cellPhone.$error || duplicatePhone
                      ? 'border-color: #ea5455 !important;'
                      : ''
                  "
                  @blur="checkCellPhone(cellPhone)"
                />
                <div class="invalid-feedback">
                  <span v-if="v$.cellPhone.$invalid">
                    Você deve informar um celular válido
                  </span>
                </div>
                <div class="invalid-feedback">
                  <span v-if="duplicatePhone"> Celular já registrado </span>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="!isPotentialPartner">
            <b-col cols="12" class="mt-1">
              <b-form-group label="Objetivo" label-for="lead-goal">
                <v-select
                  id="lead-goal"
                  v-model="goal"
                  :reduce="(lead_goal) => lead_goal.key"
                  :options="goals"
                  :loading="loading.goals"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Formação" label-for="lead-school-level">
                <v-select
                  id="lead-school-level"
                  v-model="schoolLevel"
                  :reduce="(school_level) => school_level.id"
                  :options="schoolLevels"
                  :loading="loading.schoolLevels"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Objetivo" label-for="lead-goal">
                <v-select
                  id="lead-goal"
                  v-model="goal"
                  :reduce="(lead_goal) => lead_goal.key"
                  :options="goals"
                  :loading="loading.goals"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Estado" label-for="lead-address-state">
                <v-select
                  id="lead-address-state"
                  v-model="state"
                  :reduce="(address_state) => address_state.id"
                  :options="addressStates"
                  :loading="loading.addressStates"
                  @input="addressStateChanged"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Cidade" label-for="lead-address-cities">
                <v-select
                  id="lead-address-cities"
                  v-model="city"
                  :reduce="(address_city) => address_city.id"
                  :options="addressCities"
                  :loading="loading.addressCities"
                  :disabled="!state"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              :md="origin !== ownContactOrigin ? 6 : 12"
              class="mt-1"
            >
              <b-form-group label="Origem" label-for="lead-origin">
                <v-select
                  id="lead-origin"
                  v-model="origin"
                  :reduce="(lead_origin) => lead_origin.key"
                  :options="origins"
                  :loading="loading.origins"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="origin !== ownContactOrigin"
              cols="12"
              md="6"
              class="mt-1"
            >
              <dynamic-select
                id="lead-recommendedBy"
                label="Recomendado por"
                placeholder="Digite o nome do cliente"
                v-model="recommendedBy"
                :disabled="!isCustomerRecommendation"
                :options="customers"
                :loading="loading.recommendedBy"
                @find="findCustomers"
                :class="getSelectErrorClass(v$.recommendedBy.$error)"
              />
              <div class="invalid-feedback">
                <span v-if="v$.recommendedBy.$invalid">
                  Você deve informar o cliente que recomendou
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!isPotentialPartner">
            <b-col cols="12" class="mt-1">
              <b-form-group label="Profissão" label-for="lead-occupation">
                <v-select
                  id="lead-occupation"
                  v-model="occupation"
                  :reduce="(lead_occupation) => lead_occupation.id"
                  :options="occupations"
                  :loading="loading.occupations"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="Expectativa de renda"
                label-for="lead-income"
              >
                <v-select
                  id="lead-income"
                  v-model="income"
                  :reduce="(lead_income) => lead_income.id"
                  :options="incomes"
                  :loading="loading.incomes"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="isPotentialPartner">
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group
                label="Já Empreendeu?"
                label-for="lead-was-entrepreneur"
              >
                <v-select
                  id="lead-was-entrepreneur"
                  v-model="was_entrepreneur"
                  :reduce="(option) => option.key"
                  :options="[
                    { key: 1, name: 'Sim' },
                    { key: 0, name: 'Não' },
                  ]"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" class="mt-1">
              <b-form-group label="Potencial" label-for="lead-potential">
                <v-select
                  id="lead-potential"
                  v-model="leadPotential"
                  :reduce="(leadPotential) => leadPotential.key"
                  :options="leadPotentials"
                  :loading="loading.leadPotentials"
                  label="name"
                >
                  <template #no-options="{ search, searching, loading }">
                    Sem resultados
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Currículo (apenas PDF)" label-for="id">
                <div id="resume-file-url" v-if="resumeUrl && !changeResumeFile">
                  <h5>
                    <a @click="resumeDownload" target="_blank">
                      <span class="text-primary"
                        >{{ resumeUrl | onlyFileName }}
                      </span>
                    </a>
                  </h5>
                  <b-button
                    size="sm"
                    variant="outline-primary"
                    @click.prevent="changeResumeFile = true"
                  >
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span>Alterar aquivo</span>
                  </b-button>
                </div>
                <div
                  v-else
                  class="custom-file b-form-file"
                  @click="$refs.file.click()"
                >
                  <input
                    id="resume-file-input"
                    ref="file"
                    custom-file-label="Buscar"
                    type="file"
                    class="custom-file-input"
                    accept="application/pdf"
                    style="z-index: -5"
                    @input="selectedResumeFile"
                  />
                  <label data-browse="Browse" class="custom-file-label">
                    <span v-if="!resumeFile">Anexar documento...</span>
                    <span v-else>{{ resumeFile.name }}</span>
                  </label>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-form-group label="Observação" label-for="lead-comment">
                <b-form-textarea
                  id="lead-comment"
                  v-model="comment"
                  :class="{ 'is-invalid': v$.comment.$error }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-between">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              @click="hide"
              class="lead-buttons"
            >
              Voltar
            </b-button>
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              class="lead-buttons"
            >
              {{ saving ? "Salvando..." : "Salvar" }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BSidebar,
  BForm,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormFile,
  BCol,
  BRow,
} from "bootstrap-vue";
import { mapActions, mapMutations, mapGetters } from "vuex";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import DynamicSelect from "@/modules/shared/components/DynamicSelect";
import FileChooser from "@/modules/shared/components/FileChooser";
import * as types from "../store/types";
import * as sharedTypes from "@/modules/shared/store/types";
import {
  LEAD_TYPE_PPN,
  LEAD_ORIGIN_RECOMMENDATION,
  LEAD_ORIGIN_REC_CUSTOMER,
  SELF_ORIGIN,
} from "@/constants/lead";
import { onlyDigits } from "../../../helpers/converters";
import { getVueSelectErrorClass } from "@/helpers/validators";

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormFile,
    BCol,
    BRow,
    vSelect,
    DynamicSelect,
    FileChooser,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: {
        occupations: false,
        schoolLevels: false,
        incomes: false,
        goals: false,
        recommendedBy: false,
        origins: false,
        addressStates: undefined,
        addressCities: undefined,
        leadPotentials: false,
      },
      name: undefined,
      email: undefined,
      cellPhone: undefined,
      occupation: undefined,
      schoolLevel: undefined,
      income: undefined,
      goal: undefined,
      recommendedBy: undefined,
      comment: undefined,
      state: undefined,
      city: undefined,
      origin: undefined,
      resumeFile: undefined,
      resumeUrl: undefined,
      changeResumeFile: false,
      was_entrepreneur: undefined,
      saving: false,
      leadPotential: undefined,
      duplicatePhone: false,
      duplicateEmail: false,
      emailTakenByConsultant: false
    };
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      name: { required },
      cellPhone: {
        minLength(value) {
          return value.length >= 9;
        },
        maxLength(value) {
          return value.length <= 20;
        },
      },
      occupation: {},
      email: {},
      schoolLevel: {},
      income: {},
      goal: {},
      source: {},
      recommendedBy: {
        required: requiredIf(function () {
          return (
            this.origin == LEAD_ORIGIN_RECOMMENDATION ||
            this.origin == LEAD_ORIGIN_REC_CUSTOMER
          );
        }),
      },
      comment: {},
      state: {},
      city: {},
      resumeFile: {},
      newLeadSidebar: {
        id: {},
        leadType: { required },
      },
      phoneIsDuplicated() {
        return !this.duplicatePhone;
      },
      emailIsDuplicated() {
        return !this.duplicateEmail;
      },
      emailIsTakenByConsultant() {
        return !this.emailTakenByConsultant;
      }
    };
  },
  computed: {
    ...mapGetters({
      newLeadSidebar: types.NEW_LEAD_SIDEBAR,
      leadTypes: types.LEAD_TYPES,
      occupations: types.OCCUPATIONS,
      schoolLevels: types.SCHOOL_LEVELS,
      incomes: types.INCOMES,
      goals: types.GOALS,
      origins: types.ORIGINS,
      customers: sharedTypes.CUSTOMERS,
      addressStates: sharedTypes.ADDRESS_STATES,
      addressCities: sharedTypes.ADDRESS_CITIES,
      leadPotentials: types.LEAD_POTENTIALS,
    }),
    isPotentialPartner: function () {
      return this.newLeadSidebar.leadType == LEAD_TYPE_PPN;
    },
    isCustomerRecommendation: function () {
      return (
        this.origin == LEAD_ORIGIN_RECOMMENDATION ||
        this.origin == LEAD_ORIGIN_REC_CUSTOMER
      );
    },
    resumeFileName: function () {
      return this.resumeFile?.name;
    },
    ownContactOrigin: function () {
      return SELF_ORIGIN;
    },
  },
  mounted() {
    this.loading.occupations = true;
    this.getOccupations()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as profissões para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.occupations = false;
      });
    this.loading.schoolLevels = true;
    this.getSchoolLevels()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os niveis escolares para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.schoolLevels = false;
      });
    this.loading.incomes = true;
    this.getIncomes()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar as faixas de renda para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.incomes = false;
      });
    this.loading.leadPotentials = true;
    this.getLeadPotentials()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os potenciais de lead para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.leadPotentials = false;
      });
    this.loading.addressStates = true;
    this.getAddressStates()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os estados para seleção. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.addressStates = false;
      });
  },
  methods: {
    ...mapMutations({
      mutateNewLeadSidebar: types.MUTATE_NEW_LEAD_SIDEBAR,
      mutateCustomers: sharedTypes.MUTATE_CUSTOMERS,
    }),
    ...mapActions({
      getLead: types.GET_CUSTOMER_LEAD,
      getLeadTypes: types.GET_LEAD_TYPES,
      getOccupations: types.GET_OCCUPATIONS,
      getSchoolLevels: types.GET_SCHOOL_LEVELS,
      getIncomes: types.GET_INCOMES,
      getGoals: types.GET_GOALS,
      getOrigins: types.GET_ORIGINS,
      getCustomers: sharedTypes.GET_CUSTOMERS,
      getAddressStates: sharedTypes.GET_ADDRESS_STATES,
      getAddressCities: sharedTypes.GET_ADDRESS_CITIES,
      storeLead: types.STORE_LEAD,
      downloadResume: types.DOWNLOAD_RESUME,
      getLeadPotentials: types.GET_LEAD_POTENTIALS,
      checkDuplicateCellPhone: types.CHECK_DUPLICATE_CELL_PHONE,
      checkDuplicateEmail: types.CHECK_DUPLICATE_EMAIL,
    }),
    onShow() {
      var leadType = this.newLeadSidebar.leadType;
      this.loading.origins = true;
      this.getOrigins({ leadType })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar as origens para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.origins = false;
        });
      this.loading.goals = true;
      this.getGoals({ leadType })
        .catch(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Ocorreu um erro ao carregar os objetivos para seleção. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.loading.goals = false;
        });
      if (this.newLeadSidebar.id) {
        this.getLead({ id: this.newLeadSidebar.id })
          .then((resp) => {
            var data = resp.data;
            if (data) {
              this.name = data.name;
              this.email = data.email;
              this.cellPhone = data.cell_phone ?? undefined;
              this.occupation = data.occupation_id;
              this.schoolLevel = data.school_level_id;
              this.income = data.income_id;
              this.goal = data.goal;
              this.comment = data.comment;
              this.origin = data.origin;
              this.state = data.address_state_id;
              this.city = data.address_city_id;
              this.resumeUrl = data.resume_url;
              this.was_entrepreneur = data.was_entrepreneur;
              this.leadPotential = data.consultant_lead_potential;
              if (data.customer) {
                this.mutateCustomers([data.customer]);
                this.recommendedBy = data.customer.id;
              }
              if (this.state) {
                this.loading.addressCities = true;
                this.getAddressCities({ addressState: this.state })
                  .catch(() => {
                    this.toast({
                      component: ToastificationContent,
                      props: {
                        title: "Oops!",
                        text: "Ocorreu um erro ao carregar as cidades para seleção. Entre em contato com o setor de TI.",
                        icon: "AlertTriangleIcon",
                        variant: "danger",
                      },
                    });
                  })
                  .finally(() => {
                    this.loading.addressCities = false;
                  });
              }
              this.v$.$touch();
            }
          })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar o lead para edição. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      }
    },
    async checkCellPhone(phone) {
      try {
        var trimmed = phone.replace("+", "").replaceAll(" ", "");
        const verifyLength = trimmed.length >= 13;
        if (verifyLength) {
          await this.checkDuplicateCellPhone(trimmed);
        }

        this.duplicatePhone = false;
      } catch (error) {
        this.duplicatePhone = true;
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Número de celular já existente em nossa base de dados.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async checkEmail(email) {
      try {
        const emailStringReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const emailStringCheck = emailStringReg.test(email);

        if (emailStringCheck) {
          await this.checkDuplicateEmail(email);
        }

        this.duplicateEmail = false;
        this.emailTakenByConsultant = false;
      } catch (error) {
        if (error.response.status == 400) {
          this.duplicateEmail = true;
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "E-mail já existente em nossa base de dados.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } else {
          this.emailTakenByConsultant = true;
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: "Não é possível utilizar o e-mail de um consultor.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      }
    },
    findCustomers(keyword) {
      if (keyword && keyword.length > 2) {
        this.loading.recommendedBy = true;
        this.getCustomers({ keyword })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar os clientes para seleção. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.recommendedBy = false;
          });
      }
    },
    addressStateChanged() {
      this.city = undefined;
      if (this.state) {
        this.loading.addressCities = true;
        this.getAddressCities({ addressState: this.state })
          .catch(() => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: "Oops!",
                text: "Ocorreu um erro ao carregar as cidades para seleção. Entre em contato com o setor de TI.",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          })
          .finally(() => {
            this.loading.addressCities = false;
          });
      }
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.saving = true;
      const {
        name,
        email,
        cellPhone,
        occupation,
        schoolLevel,
        income,
        goal,
        recommendedBy,
        comment,
        origin,
        state,
        city,
        resumeFile,
        was_entrepreneur,
        leadPotential,
      } = this;
      const payload = {
        id: this.newLeadSidebar.id,
        type: this.newLeadSidebar.leadType,
        name,
        email,
        cellPhone: onlyDigits(cellPhone),
        occupation,
        schoolLevel,
        income,
        goal,
        recommendedBy:
          origin !== this.ownContactOrigin ? recommendedBy : undefined,
        comment,
        origin,
        state,
        city,
        resume_file: resumeFile,
        was_entrepreneur,
        consultant_lead_potential: leadPotential,
      };
      const request = new FormData();
      for (let [key, value] of Object.entries(payload)) {
        if (value && value !== undefined && value !== "" && value !== "null") {
          request.append(key, value);
        }
      }
      this.storeLead(request)
        .then((response) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "Lead salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateNewLeadSidebar({ visible: false });
          this.newLeadSidebar.saveAction();
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: error.response?.data?.message || "Ocorreu um erro ao salvar o lead. Entre em contato com o setor de TI.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        })
        .finally(() => {
          this.saving = false;
        });
    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    selectedResumeFile(event) {
      if (event.target.files.length > 0) {
        this.resumeFile = event.target.files[0];
      }
    },
    resumeDownload() {
      this.downloadResume(this.newLeadSidebar.id)
        .then((response) => {
          setTimeout(() => {
            window.open(response.data, "_blank").focus();
          });
        })
        .catch((error) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: `Ocorreu um erro ao fazer download. Entre em contato com o setor de TI.`,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    clear() {
      this.name = undefined;
      this.email = undefined;
      this.cellPhone = undefined;
      this.occupation = undefined;
      this.schoolLevel = undefined;
      this.income = undefined;
      this.goal = undefined;
      this.recommendedBy = undefined;
      this.comment = undefined;
      this.origin = undefined;
      this.state = undefined;
      this.city = undefined;
      this.resumeFile = undefined;
      this.resumeUrl = undefined;
      this.was_entrepreneur = undefined;
      this.leadPotential = undefined;
      this.changeResumeFile = false;
      this.mutateCustomers(undefined);
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
.vue-tel-input {
  padding: 0.438rem 1rem;
}
</style>
<style lang="scss">
.sidebar-xg {
  width: 36rem;
}

.lead-buttons {
  width: 10rem;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Buscar";
}

.resume-download-col {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
