export function getRangeDates(date) {
  var dates = {
    start: undefined,
    end: undefined,
  };
  if (date && date.includes("até")) {
    dates.start = date.split(" até ")[0];
    dates.end = date.split(" até ")[1];
  } else if (date) {
    dates.start = date;
    dates.end = undefined;
  }
  return dates;
}
