export const getBRLFormat = (n) => {
  return {
    decimal: ',',
    thousands: '.',
    prefix: 'R$ ',
    suffix: '',
    precision: 2
  }
}

export const getPercentageFormat = (n) => {
  return {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: ' %',
    precision: 3
  }
}

export const getMultiplierFactorFormat = (n) => {
  return {
    decimal: ',',
    thousands: '.',
    prefix: '',
    precision: 4
  }
}
