const leadCustomer = `Seja bem-vindo à tela de Leads Cliente!

O objetivo desta tela é de ajudar o nosso parceiro de negócio a gerir seus potenciais clientes. Nela você poderá montar sua lista do Plano A, elencar/identificar os clientes mais potenciais e também classificar/encontrar potenciais clientes com maior interesse no seu serviço.
    
Legenda:
Coluna Prio. - O objetivo desta coluna é do(a) Planejador(a) Futuro priorizar os potenciais clientes pra ligar. Criar uma lista automatizada para quando realizar a atividade de ligações (plano A). Aqui é possível tanto arrastar o lead quanto editar o número de prioridade. Esta funcionalidade só irá funcionar para clientes próprios e somente se não houve nenhum outro filtro selecionado (tanto os filtros acima da tabela quanto qualquer filtro selecionado na tabela por este botão ⇳)
Total de Leads – Considera-se um Lead Cliente qualquer potencial cliente.  Temos 2 formas de cadastrar leds de clientes: Através do “Novo Lead Cliente” e cadastrando a recomendação de cliente via entrevista.
Quente – Todo lead de cliente “Quente” é um lead que está interessado no serviço ou que está avisado que você entrará em contato.
Frio – Todo lead de cliente “Frio” é um lead que não está avisado da sua ligação ou que nunca ouviu falar do serviço da Futuro Consultoria.
Desinteressados – Todo lead de cliente que já demonstrou desinteresse pelo serviço da Futuro.
A classificar – Todo Lead que precisa ser classificado entre Quente ou Frio.
EA – Entrevistas agendadas.
EF – Entrevistas feitas.   
Data de Criação – Foi a data que o Lead foi cadastrado no sistema, seja através do botão “Novo Lead Cliente” ou da recomendação de cliente via entrevista.
Data pra Lembrar – Ferramenta para lembrar a data para ligar para um determinado lead de cliente. Exemplo: quando um cliente pede pra você entrar em contato no dia X às Y horas.
`;

const customer = `Seja bem-vindo(a) à tela Clientes!

Na metodologia Futuro, consideramos um cliente um contato que fechou algum tipo de contrato, seja uma entrevista paga, uma solução financeira ou um suporte/acompanhamento. Ou seja, o cliente deixa de ser um potencial cliente (Lead) ao fechar um contrato. Portanto, quando aquele lead vira um cliente, ele deixa de existir na tela “Leads clientes” e é transferido à tela “Clientes”.

O objetivo desta tela é que o(a) Planejador(a) Futuro consiga gerir todos os seus clientes. E com os filtros, consiga também encontrar oportunidades de fazer cross-sell ou up-sell com seus clientes.

Legenda:
Suporte: todos os seus assinantes de suporte
Básico: todos os seus assinantes de acompanhamento básico
Elite: todos os seus assinantes de acompanhamento elite
Essencial: todos os seus assinantes de acompanhamento essencial
Renda: a renda do seu cliente é puxada através do preenchimento da entrevista
Poupa: o quanto o seu cliente poupa é puxado através do preenchimento da entrevista
Data pra Lembrar: o objetivo dessa ferramenta é para relembrar o Planejador Futuro de alguma tarefa como ligação, agendar uma reunião etc. Resumindo funcionará como um “reminder” para o consultor.
Status Entrevista Paga: São todos os clientes que pagaram a entrevista mas não possuem acompanhamento.
Status Solução Financeira: São todos os clientes que possuem solução financeira  mas não têm outro produto contratado (nem EP, suporte ou acompanhamento).
Dias sem contato: é o número de dias desde a última reunião feita com esse cliente.
`

const appointment = `Seja bem-vindo(a) à tela de Compromissos!

O objetivo desta funcionalidade é de ajudar o(a) Planejador(a) Futuro à organizar os seus compromissos com seus clientes. Além disso, você poderá identificar os seus clientes e da sua franquia com maior poder aquisitivo (“Big Shot”) e quem irá atende-los. Assim você e sua equipe poderão traçar a melhor estratégia para melhorar a performance de atendimento.
 
Ação “novo compromisso”: Ao criar um novo compromisso um e-mail será encaminhado automaticamente para o seu cliente com o link da reunião. Ao incluir um PN especialista esse compromisso aparecerá automaticamente no sistema desse especialista.
Ação “remover compromisso”: Só será possível deletar compromissos que antecedem a data e o horário da reunião. Compromissos que já passaram, não serão possíveis de excluí-los.
Ação “Report compromisso”: Você precisa fazer o report dentro de 24h, caso contrário após 24h o seu sistema bloqueará qualquer outra ação até finalizar este report.

Legenda:
MXA: Momento X Agendado
PA: Parceria Agendada
`

const infos = {
    leadCustomer,
    customer,
    appointment
};

export default function getInfo(key){
   return infos[key]
};
