export const LEAD_TYPE_PPN = 'PPN'
export const LEAD_TYPE_PCL = 'PCL'

export const LEAD_ORIGIN_RECOMMENDATION = "recommendation"
export const LEAD_ORIGIN_REC_CUSTOMER = "rec_customer"

export const LANDING_PAGE = "landing_page";
export const ADMIN = "admin";
export const REC_PN = "rec_pn";
export const OWN_CONTACT = "self";
export const CV_CATHO = "cv_catho";
export const CV_LINKEDIN = "cv_linkedin";
export const CV_OTHERS = "cv_others";

export const SELF_ORIGIN = "self";
export const ORIGIN_CAMPAIGN = "campaign";