<template>
  <div>
    <b-sidebar
      id="sidebar-comment"
      sidebar-class="sidebar-lg"
      :visible="commentSidebar.visible"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => mutateCommentSidebar({ visible: val })"
      @hidden="clear"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h4>{{commentSidebar.title}}</h4>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="onSubmit"
          @reset.prevent="resetForm"
        >
          <b-form-group
            label="Comentário"
            label-for="comment-area"
          >
            <b-form-textarea
              id="comment-area"
              v-model="commentSidebar.comment"
              :class="{ 'is-invalid': v$.$error }"
            />
            <div class="invalid-feedback">
              <span v-if="v$.$error">
                Você deve informar um comentário
              </span>
            </div>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="saving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ saving ? 'Salvando...' : 'Salvar' }}
            </b-button>
          </div>
        </b-form>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormFile, BAvatar, BButton, BFormInvalidFeedback, BFormTextarea
} from 'bootstrap-vue'
import { mapMutations, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormTextarea,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      saving: false,
    }
  },
  computed: {
    ...mapGetters({
      commentSidebar: types.COMMENT_SIDEBAR
    })
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations(){
    return {
      commentSidebar: {
        id: { required },
        comment: { required }
      }
    }
  },
  methods: {
    ...mapMutations({
      mutateCommentSidebar: types.MUTATE_COMMENT_SIDEBAR
    }),
    clear(){
      this.commentSidebar.comment = undefined
      this.v$.$reset();
    },
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.saving = true
      const{
        id,
        comment
      } = this.commentSidebar
      this.commentSidebar.saveAction({
        id,
        comment
      })
      .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Sucesso",
              text: "O comentário foi salvo com sucesso!",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
          this.mutateCommentSidebar({visible: false})
        })
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao salvar o comentário. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.saving = false;
      });
    }
  }
}
</script>
