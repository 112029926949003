<template>
  <div>
    <v-select
      v-bind="$attrs"
      append-to-body
      :calculate-position="withPopper"
      :value="selectedValue"
      @input="handleInput"
    />
  </div>
</template>

<script>
import { createPopper } from "@popperjs/core";
import vSelect from "vue-select";

export default {
  components: { vSelect },
  inheritAttrs: false, // Vai permitir que as props do v-select sejam usadas quando o componente for importado em outra tela.
  props: ["value", "onInputChange"],
  data: () => ({ placement: "bottom", selectedValue: null }),
  created() {
    this.selectedValue = this.value;
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width;
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -1],
            },
          },
          {
            name: "toggleClass",
            enabled: true,
            phase: "write",
            fn({ state }) {
              component.$el.classList.toggle(
                "drop-up",
                state.placement === "top"
              );
            },
          },
        ],
      });
      return () => popper.destroy();
    },
    handleInput(value) {
      this.selectedValue = value;
      this.$emit("input", value);
      if (typeof this.onInputChange === "function") {
        this.onInputChange(value);
      }
    },
  },
};
</script>

<style>
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}
</style>
