import Sortable from 'sortablejs';

const createSortable = (el, options, vnode) => {
    return Sortable.create(el, {
        ...options
    });
};

export const sortable = {
    name: 'sortable',
    bind(el, binding, vnode) {
        const table = el;    
        table._sortable = createSortable(table.querySelector("tbody"), binding.value, vnode);
    }
};
